import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../../components/Banner/Banner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import LoginForm from '../../../../components/Login/Login';
import * as styles from '../articles.module.scss';
import ArticleImg from '../../../../assets/IMG/freebies/employee-dismiss/1.png';
import articlePDF from "../../../../assets/pdfs/employee dismiss.pdf";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from "react-share";
import { API_BaseURL } from '../../../../Services/ApiRoutes';
import { Link } from 'gatsby';

interface Props {
  location: string;
}

const EmployeeDismissArticle: React.FC<Props> = ({ location }) => {
  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const fourthRef = useRef(null);
  const fifthRef = useRef(null);
  const shareURL: string = location ? `${(API_BaseURL === "/api/" && !location.toLowerCase().includes("beta") ? "https://primehr.co.uk/" : "https://beta.primehr.co.uk/")}Articles/employee-dismiss` : "";
  const scroll = (ref: any) => ref.current.scrollIntoView({ behavior: "smooth" });

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Articles :: HOW TO DISMISS AN EMPLOYEE - THE ESSENTIAL DOS AND DON'TS</title><meta charSet='utf-8' />
        <meta property="og:description" content="Running a business can
be really stressful.
Especially when you’ve
got an employee that’s
causing you lots of
issues." />
        <meta property="og:image" content={ArticleImg} />
        <meta property="og:title" content="PrimeHR :: HOW TO DISMISS AN EMPLOYEE - THE ESSENTIAL DOS AND DON'TS" />
        <meta property="og:site_name" content="PrimeHR" />
        <meta property="og:url" content="https://primehr.co.uk/Articles/employee-dismiss" />
      </Helmet>

      <Banner />

      <Breadcrumb Trail={[
        { To: "/Articles", Text: "Articles" },
        { To: "", Text: "Team Performance" }
      ]} />

      <div className={styles.Section}>
        <div className={styles.SectionInner}>
          <h1>HOW TO DISMISS AN EMPLOYEE</h1>
          <div className={styles.Social}>
            <FacebookShareButton url={shareURL}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={shareURL}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton url={shareURL}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>

            {/* <span>Written by <Link to="https://www.linkedin.com/in/nikki-hufton-bsc-hons-mcipd-fcmi-mnlp-a7528854/">Nikki Hufton</Link></span> */}
          </div>

          <div className={styles.SectionImage}>
            <img loading={"lazy"} src={ArticleImg} />
          </div>

          <h2>THE ESSENTIAL DOS AND DON'TS</h2>

          <ul>
            <li><a onClick={() => scroll(firstRef)}>Why Might You Want to Let an
              Employee Go?</a></li>
            <li><a onClick={() => scroll(secondRef)}>Disciplinary Procedure
              - Before You Begin</a></li>
            <li><a onClick={() => scroll(thirdRef)}>The Process</a></li>
            <li><a onClick={() => scroll(fourthRef)}>Think of Everyone Else</a></li>
          </ul>

          <p>
            Running a business can
            be really stressful.
            Especially when you’ve
            got an employee that’s
            causing you lots of
            issues.
          </p>
          <p>
            As an employer, it’s likely
            that at some point you
            might feel that it would be
            better for everybody to
            let an employee go.
          </p>
          <p>
            And in some cases, this might be the right
            decision.
          </p>
          <p>
            However, there are high risks involved when
            dismissing an employee. Which is why it’s
            absolutely essential that you have a valid
            reason to do so and that you follow a fair
            procedure every step of the way.
          </p>
          <p>
            If you fail to do this, you risk a tribunal claim
            which will be even more stressful.
          </p>
          <p>
            That’s why we've created this guide – to help
            you understand the steps you would take as
            an experienced HR expert to help you dismiss
            an employee.
          </p>
          <p>
            But, make no mistake, if you are planning on
            dismissing an employee, this guide is no
            substitute for speaking and getting the
            advice of a legally qualified HR expert first.
          </p>
          <p>
            If you find yourself in this position, please get
            in touch with us today.
          </p>

          <h3 ref={firstRef}>Why Might You Want to Let an Employee Go?</h3>
          <p>
            There are so many reasons you may want to
            dismiss an employee for. Sadly, many of
            them simply won’t fly when it comes to
            abiding by employment law.
          </p>
          <p>
            Before you take any action it’s always
            advisable to do your research and make sure
            you’re not discriminating against an
            employee, or acting in a way that could land
            you in an unfair dismissal case.
          </p>
          <p>
            Some of the acceptable
            reasons may be:
          </p>
          <p>• Someone is underperforming</p>
          <p>• They’re not a good fit for the business</p>
          <p>• They’re causing problems - this may be
            bullying, harassment, or discrimination,
            for example</p>
          <p>• Not being able to do their job properly</p>
          <p>• Violence, theft, vandalism (gross
            misconduct)</p>
          <p>• Statutory restrictions - if a driver loses
            their licence, for example</p>
          <p>
            Even in these cases there is still a
            process that you MUST follow before
            handing an employee their P45.
          </p>

          <h3 ref={secondRef}>Disciplinary Procedure - Before You Begin</h3>
          <p>
            Unless you’re dealing with gross
            misconduct, it’s unlikely that you can
            jump straight to firing an employee.
          </p>
          <p>
            First, you must follow your disciplinary
            procedure, as outlined in your own
            disciplinary policy, and give your
            employee warnings, as well as the
            opportunity to put things right (if
            applicable).
          </p>
          <p>
            Never be tempted to rush this process. As
            much as you may want the employee out
            of the business, taking things too quickly
            can lead you straight to a tribunal.
          </p>
          <p>
            As soon as you begin the process of
            dismissing an employee you should be
            taking notes and keeping a record of
            everything relating to that employee.
            This includes notes on their misconduct,
            performance, or other, and any evidence
            or investigation that you carry out to
            back up any allegations.
          </p>
          <p>
            Written notes can be used as evidence
            that you’ve followed procedure correctly,
            and that you’ve carried out a fair process
            while trying to avoid dismissal.
          </p>
          <p>
            If you can’t clearly prove the reason for
            dismissal, you may be accused of not
            acting fairly or not having a valid reason
            for your action.
          </p>
          <p>
            Ask yourself: “Why do I need to fire this
            employee?”
          </p>
          <p>
            If you can’t simply explain the reason and
            evidence why, it’s possible that your
            motivation is unfair or even
            discriminatory. It is absolutely vital that
            you’re not discriminating against any
            protected characteristics, such as age,
            religion, or gender. Again, such
            motivation could land you at a tribunal.
          </p>
          <p>
            Remember, while employees with 2 or
            more years of continuous service have a
            legal right to challenge your decision to
            dismiss them, if the reason is
            discriminatory, anyone with any length of
            service can challenge you.
          </p>

          <h3 ref={thirdRef}>The Process</h3>

          <p>
            Once you’ve investigated and
            gathered any evidence you feel is
            necessary, you’ll need to decide if
            there is a case to answer. If so, then
            arrange a disciplinary meeting. This
            should be in private, away from
            other employees’ eyes and ears.
          </p>
          <p>
            You must invite your employee to attend
            the meeting, in writing. A letter should
            inform them of the time and date of the
            meeting, advise them of their right to be
            accompanied, and it should also state that
            dismissal could be a potential outcome of
            the meeting.
          </p>
          <p>
            Outline the reasons for the meeting and
            why dismissal is a consideration. You
            should also provide the evidence you have
            so that your employee has time to review it
            before the meeting.
          </p>
          <p>
            When the meeting begins, it’s your job to
            explain the allegations to your employee.
            Be as detailed and specific as you can, go
            over the evidence from your investigation,
            and give them the opportunity to
            comment and respond to it all.
          </p>
          <p>
            At this stage it’s very important to take
            notes. In fact, you should ideally have a
            note-taker present to allow you to give
            your full attention to the meeting. This
            person can also act as a witness on your
            behalf.
          </p>
          <p>
            Expect there to be an emotional response
            from your employee. While this is normal,
            it’s important that you aren’t swayed by
            this and remain on track with your
            objectives. Have them written down in
            front of you as a memory aid if you think
            you might need it.
          </p>
          <p>
            Once you’ve explained things to your
            employee and given them suitable
            opportunity to respond, you should
            adjourn the meeting. This is to give you
            adequate time to consider what they’ve
            told you and to make a final decision.
          </p>
          <p>
            Explain what will happen to your employee
            and let them know how long it will take
            you to come to a decision. It could be later
            the same day, the next day, or even in a
            few days’ time. As long as you don’t have
            an unnecessary delay, while also giving
            yourself ample time to fully consider the
            situation. Of course, the complexity of the
            situation may also have bearing on how
            long your decision may take. Just make
            sure to keep your employee informed of
            when the outcome meeting will take
            place.
          </p>
          <p>
            Remember, you will need to make a fair
            decision that is consistent with how you’ve
            acted in the past. If you’ve had a similar
            situation, it’s a good idea to look at how
            you dealt with that so that you don’t act
            unfairly.
          </p>
          <p>
            You may find that, when it’s time for the
            outcome meeting, you still haven’t made a
            decision and you need additional time to
            gather more evidence or investigate
            further. That’s ok, but you need to
            communicate this with your employee as
            soon as possible and notify them of the
            new meeting.
          </p>
          <p>
            Whatever your decision - to keep your
            employee or to dismiss them - you need to
            reiterate your reasoning and inform them
            of what is expected next. If you keep your
            employee this may involve a performance
            improvement plan or some training, for
            example.
          </p>
          <p>
            Follow everything up in writing, and repeat
            what was discussed at the meeting.
            Remind your employee of their right to
            appeal the decision and the date that this
            must be done by. If you’re dismissing your
            employee, you’ll need to inform them of
            their last day of employment, pay
            arrangements including annual leave, and
            the return of any work property.
          </p>

          <h3 ref={fourthRef}>Think of Everyone Else</h3>

          <p>
            If you’re running a small business,
            it’s highly likely that someone
            being dismissed will have an
            impact on the team.
          </p>
          <p>
            Once you’ve made your decision and
            informed the employee in question of the
            outcome, you should also let everyone else
            know what’s happening.
          </p>
          <p>
            Without going into unnecessary detail,
            inform the team that their colleague will
            be leaving, and reassure them that it’s not
            a company issue, but an individual one.
            Explain that their jobs aren’t at risk, and if
            appropriate remind them of the
            consequences of serious behaviour and
            poor performance.
          </p>
          <p>
            You may also wish to invite anyone to
            speak with you privately if they have any
            further concerns or questions.
          </p>
          <p>
            Remember, it’s a stressful process, whether
            it’s the first time or the 10th. However,
            taking the right time to carefully plan your
            disciplinary process and meetings will help
            to ensure you take all the correct steps and
            don’t act unreasonably at any stage.
            Failing to act fairly or without
            discrimination can have serious
            consequences for your business, so
            prepare well and make sure the process is
            objectively conducted throughout.
          </p>
          <p>
            If you’d like some expert
            guidance and advice,
            we’re always available to
            help. Just give us a call.
          </p>

          <h3 ref={fifthRef}>Dismissing an Employee</h3>

          <p>
            Your dos and don’ts checklist
          </p>
          <p>
            <b>DO...</b>
          </p>
          <p>• have a valid reason for wanting to
            dismiss an employee</p>
          <p>• make sure you’re not discriminating
            against any protected characteristics</p>
          <p>• follow your own disciplinary policy</p>
          <p>• give your employee adequate
            warnings before dismissal</p>
          <p>• keep a written record of everything</p>
          <p>• sufficiently investigate before a
            disciplinary meeting</p>
          <p>• keep your employee informed at
            each stage of the process</p>
          <p>• give your employee sufficient
            opportunity to respond to allegations</p>
          <p>• take reasonable time to consider the
            outcome</p>
          <p>• follow everything up in writing</p>
          <p>• keep other employees informed as
            appropriate</p>
          <p>
            <b>DON’T</b>
          </p>
          <p>• discriminate</p>
          <p>• rush through the disciplinary process</p>
          <p>• skip any steps</p>
          <p>• forget to take notes and have a witness
            in your meetings</p>
          <p>• be swayed by any emotional reactions</p>
          <p>• be inconsistent with your decisions</p>
          <p>• give other employees too much detail
            on the situation</p>
          <p>• act alone if you’re unsure of the correct
            protocol or employment laws!</p>
        </div>
        <div className={styles.SectionSide}>
          <div className={styles.Download}>
            <a download href={articlePDF}>
              <i>
                <FontAwesomeIcon icon={faCloudDownloadAlt} />
              </i>
              Download Article
            </a>
          </div>

          <div className={styles.SectionSideInfo}>
            <h4>Found This Article Useful?</h4>
            <p>If you found this article or any of our FREE resources useful then sign up now to start using our amazing new features and tools, and take advantage of our <Link to={"/Packages/Silver"}>Silver</Link> and <Link to={"/Packages/Silver"}>Gold</Link> Packages!</p>

            <div className={styles.Download}>
              <Link to="/Register">
                Create an Account
              </Link>
            </div>
          </div>

          <h4><span>Or</span></h4>

          <LoginForm />
        </div>
      </div>
    </>
  );
};

export default EmployeeDismissArticle;