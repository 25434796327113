import React from 'react';
import Layout from '../../components/Layout/layout';
import EmployeeDismissArticle from '../../Views/Website/Articles/Employee-Dismiss/Employee-Dismiss';

const EducationalGuideArticlePage: React.FC = (props: any) => {
  return (
    <Layout>
      <EmployeeDismissArticle location={props.location.href} />
    </Layout>
  );
};

export default EducationalGuideArticlePage;
